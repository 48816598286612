import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Research from './pages/research';
import Contact from './pages/contact';
import News from './pages/news';
import Publications from './pages/publications';
import Source from './pages/source';
import Group from './pages/group';
import NotFound from './pages/404';

function App() {
	return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/research" element={<Research />}/>
                <Route path="/news" element={<News />}/>
                <Route path="/publications" element={<Publications />}/>
                <Route path="/source" element={<Source />}/>
                <Route path="/group" element={<Group />}/>
                <Route path="/contact" element={<Contact />}/>
                <Route path="/*" element={<NotFound />}/>

            </Routes>
        </Router>
        
	);
}

export default App;
